<template>
  <itemCard :data="computedData" :action="action" :actionText="actionText" />
</template>

<script>
export default {
  components: {
    itemCard: () => import("@/modules/base/components/itemCard/itemCard.vue"),
  },
  props: {
    data: Object
  },
  computed: {
    computedData() {
      return [
        {
          text: "客戶",
          value: this.data.store_name,
        },
        {
          text: "訂單編號",
          value: this.data.order_no,
        },
        {
          text: "訂單時間",
          value: this.$helper.orderTimeFormat(this.data.created_at),
          class: this.statusColorClass,
        },
      ];
    },
    statusColorClass() {
      if (
        this.data.status ==
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
      )
        return "red--text";
      return "primary--text";
    },
    actionText() {

      return this.data.status;
    },
  },
  methods: {
    action() {
      this.$router.push({
        name: "backOrder-detail",
        params: { orderId: this.data.order_no },
      });
    },
  },
};
</script>